import { all } from 'redux-saga/effects'
import career from './career/saga'
import CrackscreenSaga from './crackscreen/saga'
import LoadingSaga from './loading/saga'
import ErrorSaga from './error/saga'

export default function * rootSaga (getState) {
  yield all([
    // userSagas(),
    career(),
    CrackscreenSaga(),
    LoadingSaga(),
    ErrorSaga()
  ])
}
