import Cookies from 'universal-cookie'
import {getInitData} from '../../helpers/urlSync'

const cookies = new Cookies()
const initData = getInitData()

if(initData.language){
  cookies.set('language', initData.language,  { path: '/'})
}
const config = {
	options: [
		{
			languageId: "indonesian",
			locale: "id",
			text: "Indonesian",
			symbol:"ID"
		},
		{
			languageId: "english",
			locale: "en",
			text: "English",
			symbol:"EN"
		},

	]
}


export function getCurrentLanguage(lang) {
	let	selecetedLanguage = config.options[0]
	let selectedLangCode = cookies.get('language')
	if(selectedLangCode === 'en'){
		selecetedLanguage = config.options[1]
	}
	else{
		selecetedLanguage = config.options[0]
	}

	config.options.forEach(language => {
		if (language.languageId === lang) {
			selecetedLanguage = language
		}
	})
	return selecetedLanguage
}
export default config
