import actions from "./actions"

const initState = {
	fileLists: []
}

export default function uploadReducer(state = initState, {type, payload}) {
	switch (type) {
	case actions.ADD_FILE_LISTS:
		return [...state.fileLists]
	default:
		return state
	}
}
