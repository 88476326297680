import App from './app/reducer'
import LanguageSwitcher from './languageSwitcher/reducer'
import Payment from './payment/reducer'
import step from './step/reducer'
import quoteDetails from './quote/reducer'
import Upload from './upload/reducer'
import Career from './career/reducer'
import Crackscreen from './crackscreen/reducer'
import Loading from './loading/reducer'
import Error from './error/reducer'

export default {
  App,
  LanguageSwitcher,
  Payment,
  quoteDetails,
  step,
  Upload,
  Career,
  Crackscreen,
  Loading,
  Error
}
