import actions from './actions'
const initState = {
  quoteData: {}
}

export default function errorReducer (state = initState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.GET_CRACKSCREEN_BRAND_MODELS_SUCCESS:
    case actions.GET_CRACKSCREEN_BRAND_MODELS_FAILURE:
      return {
        ...state,
        phoneBrandModels: action.data
      }
    case actions.GET_CRACKSCREEN_PREMIUM_SUCCESS:
    case actions.GET_CRACKSCREEN_PREMIUM_FAILURE:
      return {
        ...state,
        premiumList: action.data
      }
    case actions.GET_CRACKSCREEN_COVERAGE_PERIOD_SUCCESS:
    case actions.GET_CRACKSCREEN_COVERAGE_PERIOD_FAILURE:
      return {
        ...state,
        coveragePeriodList: action.data
      }
    case actions.CREATE_CRACKSCREEN_QUOTE_SUCCESS:
    case actions.CREATE_CRACKSCREEN_QUOTE_FAILURE:
      return {
        ...state,
        quoteData: action.data
      }
    case actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_SUCCESS:
    case actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_FAILURE:
      return {
        ...state,
        discountAmount: action.discountAmount,
        calculateCrackscreenCouponMsg: action.message,
        applyCouponSuccess: action.success
      }
    case actions.RESET_CRACKSCREEN_STATES:
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
