import actions from "./actions"

const initState = {
	isPaymentModalOpen: false
}

export default function paymentReducer(state = initState, action) {
	switch (action.type) {
	case actions.MAKE_PAYMENT:
		return {
			...state,
			data: {...action.payload}
		}
	case actions.MODAL_PAYMENT:
		return {
			...state,
			data: {...action.payload}
		}
	default:
		return state
	}
}
