import dev from './dev-config'
import prod from './prod-config'

// Default to dev if not set
const config = process.env.REACT_APP_HOST_ENV === 'prod'
  ? prod
  : dev

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}
