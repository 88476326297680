import urls from '../config/urls'
import axios from 'axios'

const configUrl = urls.url

const getUploadDocs = payload => {
  return axios.post(configUrl.uploadDoc, payload, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
const applyJobapi = payload => {
  return axios.post(configUrl.applyJobapi, payload, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export default {
  getUploadDocs,
  applyJobapi
}
