/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
// import { Icon } from 'antd'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { StyledHeader } from './index.style'
import { IMAGE_URL } from './content'
import { getLanguageLabelByLocale } from './utility'
import { FormattedMessage } from 'react-intl'
import MobileLanguageSwitcher from './components/MobileLanguageSwitcher'

// import config from '../../config'

// const { HERO_CONFIG } = config

class AppHeader extends Component {
  constructor (props) {
    super(props)
    this.dropdownButton = React.createRef()
    this.state = {
      productDropdownVisible: false,
      langDropdownVisible: false,
      collapsed: false,
      url: '',
      showArticles: false,
      showLocales: false,
      showOurInnovations: false
    }
  }

  handleProductClick = () => {
    this.setState({
      productDropdownVisible: !this.state.productDropdownVisible
    })
  }
  handleLangClick = () => {
    this.setState({
      langDropdownVisible: !this.state.langDropdownVisible
    })
  }

  handleClickOutside = event => {
    if (this.dropdownButton.current && !this.dropdownButton.current.contains(event.target)) {
      this.setState({
        productDropdownVisible: false,
        langDropdownVisible: false
      })
    }
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.getUrl()
  }
  componentWillMount () {
    this.setState({
      productDropdownVisible: false
    })
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  resetPoli () {
    document.getElementById('nav-check').checked = false
  }

  getUrl = (e) => {
    this.setState({ url: e })
    this.resetPoli()
  }

  render () {
    const { onLocaleChange: handleLocaleChange, locale } = this.props
    const { showLocales } = this.state
    const setShowLocales = (showLocale) => {
      this.setState({ ...this.state, showLocales: showLocale })
    }
    return (
      <div>
        <StyledHeader>
          <div className='nav'>
            <input type='checkbox' id='nav-check' />
            <div className='nav-header'>
              <div className='nav-title'>
                <Link id='link_header_Pasarpolis.com' to='/' onClick={() => this.getUrl('')}>
                  <img src={IMAGE_URL.logo} alt='pasarpolis_logo' width='100%' />
                </Link>
              </div>
            </div>
            <div className='nav-btn'>
              <label for='nav-check'>
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className={`nav-links ${locale === 'vn' ? 'end' : ''} `}>
              {locale !== 'vn'
                ? (<div className='navbar nav-left'>
                  {/* <NavLink to='/about' className={this.state.url === 'about' && 'nav-bar-selcted'} onClick={() => this.getUrl('about')}>
                  <FormattedMessage id='Tentang PasarPolis' />
                  <span className='nav-bar-hover' />
                </NavLink> */}
                  <a
                    id='link_header_about'
                    className={this.state.url === 'about' && 'nav-bar-selcted'}
                    href='/about'
                    onClick={() => this.getUrl('about')}
                  >
                    <FormattedMessage id='Tentang PasarPolis' />
                    <span className='nav-bar-hover' />
                  </a>
                  <div className='navbar__dropdown' onMouseEnter={() => this.setState({ showOurInnovations: true })} onClick={() => this.setState({ showOurInnovations: !this.state.showOurInnovations })}>
                    <div className='navbar__dropdown-text'>
                      <FormattedMessage id='Inovasi Kami' />
                    </div>
                    <img src='https://storage.googleapis.com/pp_img/website/blog/blogs-arrow-sm.svg' className={'navbar__dropdown-icon' + (this.state.showOurInnovations ? ' navbar__dropdown-icon--active' : '')} />
                    {this.state.showOurInnovations && (
                      <div className='navbar__menu' onMouseLeave={() => this.setState({ showOurInnovations: false })}>
                        <div className='navbar__content'>
                          <a href='https://eb.pasarpolis.io/' className={this.state.url === '' ? 'navbar__dropdown-item navbar__dropdown-item__selected' : 'navbar__dropdown-item'}>
                            <FormattedMessage id='Employee Benefit' />
                          </a>
                          <a href='https://mitra.pasarpolis.io/' className={this.state.url === '' ? 'navbar__dropdown-item navbar__dropdown-item__selected' : 'navbar__dropdown-item'}>
                            <FormattedMessage id='Pasarpolis Mitra' />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <NavLink id='link_header_Klaim' className={this.state.url === 'claim' && 'nav-bar-selcted'} to='/claim' onClick={() => this.getUrl('claim')}>
                    <FormattedMessage id='Ajukan Klaim' />
                    <span className='nav-bar-hover' />
                  </NavLink>
                  {/* <NavLink to='/career' className={this.state.url === 'career' && 'nav-bar-selcted'} onClick={() => this.getUrl('career')}>
                  <FormattedMessage id='Karir' />
                  <span className='nav-bar-hover' />
                </NavLink> */}
                  <a
                    id='link_header_career'
                    className={this.state.url === 'career' && 'nav-bar-selcted'}
                    href='/career'
                    onClick={() => this.getUrl('career')}
                  >
                    <FormattedMessage id='Karir' />
                    <span className='nav-bar-hover' />
                  </a>
                  <div className='navbar__dropdown' onMouseEnter={() => this.setState({ showArticles: true })} onClick={() => this.setState({ showArticles: !this.state.showArticles })}>
                    <div className='navbar__dropdown-text'>
                      <FormattedMessage id='Artikel' />
                    </div>
                    <img src='https://storage.googleapis.com/pp_img/website/blog/blogs-arrow-sm.svg' className={'navbar__dropdown-icon' + (this.state.showArticles ? ' navbar__dropdown-icon--active' : '')} />
                    {this.state.showArticles && (
                      <div className='navbar__menu' onMouseLeave={(() => this.setState({ showArticles: false }))}>
                        <div className='navbar__content'>
                          <a href={`/news`} id='link_header_News' className={this.state.url === '/news' ? 'navbar__dropdown-item navbar__dropdown-item--selected' : 'navbar__dropdown-item'}>
                            <FormattedMessage id='Siaran Pers' />
                          </a>
                          <a href={`/blogs`} id='link_header_Bolg' className={this.state.url === '/blogs' ? 'navbar__dropdown-item navbar__dropdown-item--selected' : 'navbar__dropdown-item'}>
                            <FormattedMessage id='Blog' />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <a id='link_header_Faq' className={this.state.url === 'faq' && 'nav-bar-selcted'} href='/faq' onClick={() => this.getUrl()}>
                    FAQ
                    <span className='nav-bar-hover' />
                  </a>
                </div>)
                : null
              }
              <div className='navbar nav-right'>
                <div className='navbar__dropdown desktop' onMouseEnter={() => setShowLocales(true)} onClick={() => setShowLocales(!showLocales)}>
                  <div className='navbar__dropdown-text'>
                    {
                      getLanguageLabelByLocale(locale)
                    }
                  </div>
                  <img alt='arrow down' src='https://storage.googleapis.com/pp_img/website/blog/blogs-arrow-sm.svg' className={'navbar__dropdown-icon' + (showLocales ? ' navbar__dropdown-icon--active' : '')} />
                  {showLocales && (
                    <div className='navbar__menu' onMouseLeave={(() => setShowLocales(false))}>
                      <div className='navbar__content'>
                        { /* // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        */
                        }
                        <a
                          id='link_header_News'
                          className={locale === 'en' ? 'navbar__dropdown-item navbar__dropdown-item--selected' : 'navbar__dropdown-item'}
                          onClick={() => handleLocaleChange('en')}
                        >
                          English
                        </a>
                        <a
                          id='link_header_Bolg'
                          className={locale === 'id' ? 'navbar__dropdown-item navbar__dropdown-item--selected' : 'navbar__dropdown-item'}
                          onClick={() => handleLocaleChange('id')}
                        >
                          Indonesia
                        </a>
                        <a
                          id='link_header_Bolg'
                          className={locale === 'vn' ? 'navbar__dropdown-item navbar__dropdown-item--selected' : 'navbar__dropdown-item'}
                          onClick={() => handleLocaleChange('vn')}
                        >
                          Vietnam
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <MobileLanguageSwitcher
                onLocaleChange={handleLocaleChange}
                currentLocale={locale}
              />
            </div>
          </div>
        </StyledHeader >
      </div >
    )
  }
}

export default connect(state => ({}), {
})(AppHeader)
