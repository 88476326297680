import { useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
const USER_DEFAULT_LANG = 'id'

const IntlAppWrapper = ({ locale, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState({})
  useEffect(() => {
    const getMessages = async () => {
      const messages = await import(`../../lang/${locale}.json`)
      setIsLoading(false)
      setMessages(messages)
    }
    getMessages()
    setIsLoading(true)
  }, [locale])
  return (
    <IntlProvider
      locale={locale}
      messages={messages}
    >
      {isLoading ? <div></div> : props.children}
    </IntlProvider>
  )
}

export default IntlAppWrapper
