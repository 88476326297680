const actions = {
	MAKE_PAYMENT: "MAKE_PAYMENT",
	MODAL_PAYMENT: "MODAL_PAYMENT",
	makePayment: (payload) => ({
		type: actions.MAKE_PAYMENT,
		payload
	}),
	modalPayment: payload => ({
		type: actions.MODAL_PAYMENT,
		payload
	})
}
export default actions
