export default {
  getMasterData: `${window.location.origin}/api/v1/master/tags/{tagName}/`,
  getPhoneModels: `${window.location.origin}/api/v1/master/phone-models/`,
  getCrackScreenCoveragePeriod: `${window.location.origin}/api/v1/master/tags/crack_screen_ultra_coverage_period/`,
  getCrackScreenCoveragePeriodPremium: `${window.location.origin}/api/v1/master/tags/crack_screen_ultra_premium_v2/`,
  getCrackScreenPremium: `${window.location.origin}/api/v1/master/tags/crack_screen_ultra_premium/`,
  createCrackScreenQuote: `${window.location.origin}/api/quote/create-crack-screen-ultra/`,
  validateCreateQuote: `${window.location.origin}/api/v1/master-quote/validate-create-quote/`,
  applyQuoteCoupon: `${window.location.origin}/api/v1/master-quote/{quoteId}/apply-coupon/`,
  calculateCoupon: `${window.location.origin}/api/v1/master-quote/calculate-coupon/`
}
