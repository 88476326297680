export const getLanguageLabelByLocale = (locale) => {
  switch (locale) {
    case 'en':
      return 'English'
    case 'id':
      return 'Indonesia'
    default:
      return 'Indonesia'
  }
}
