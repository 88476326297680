import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import actions from './actions'
import callApi from '../../helpers/callApi'
import get from 'lodash.get'

const {
  UPLOAD_DOC_REQUEST,
  UPLOAD_DOC_SUCCESS,
  UPLOAD_DOC_ERROR,
  APPLY_DOC_REQUEST,
  APPLY_DOC_SUCCESS,
  APPLY_DOC_ERROR
} = actions

export function * applyJobDocUpload () {
  yield takeEvery(UPLOAD_DOC_REQUEST, function * (data) {
    try {
      let res = yield call(callApi.getUploadDocs, data.payload)
      if (res.status === 201 && res.data) {
        let file = {
          status: 'done',
          name: get(res, 'data.data.filename', ''),
          url: res.data.data['url']
        }
        yield put({
          type: UPLOAD_DOC_SUCCESS,
          data: file,
          message: 'success'
        })
      } else {
        yield put({
          type: UPLOAD_DOC_ERROR,
          data: [],
          message: 'error'
        })
      }
    } catch (error) {
      yield put({
        type: UPLOAD_DOC_ERROR,
        data: [],
        message: 'error'
      })
    }
  })
}
export function * submitJobApplication () {
  yield takeEvery(APPLY_DOC_REQUEST, function * (data) {
    try {
      let res = yield call(callApi.applyJobapi, data.payload)
      if (res.status === 201) {
        yield put({
          type: APPLY_DOC_SUCCESS,
          message: 'success'
        })
      } else {
        yield put({
          type: APPLY_DOC_ERROR,
          message: 'error'
        })
      }
    } catch (error) {
      yield put({
        type: APPLY_DOC_ERROR,
        message: 'error'
      })
    }
  })
}
export default function * rootSaga () {
  yield all([
    fork(applyJobDocUpload),
    fork(submitJobApplication)
  ])
}
