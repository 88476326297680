// eslint-disable-next-line import/no-anonymous-default-export
export default {
  url: {
    // createQuote: `${process.env.REACT_APP_URL}/api/quote/create_master_quote/`,
    // uploadQuotePhoto: `${process.env.REACT_APP_URL}/api/quote/upload_quote_photo/`,
    getBrand: `${process.env.REACT_APP_URL}/api/integration-layer/vehicle_brands/`,
    getModel: `${process.env.REACT_APP_URL}/api/integration-layer/vehicle_models/`,
    checkPaymentStatus: `${process.env.REACT_APP_URL}/api/v1/master-payment/check-payment-status/`,
    getArea: `${process.env.REACT_APP_URL}/api/integration-layer/list_area_codes/`,
    getModelDetailsFromID: `${process.env.REACT_APP_URL}/api/integration-layer/vehicle_models/`,
    // getHealthPackage: `${process.env.REACT_APP_URL}/api/v1/products/lippo-health-protection/packages/`,
    // getLippoNationalities: `${process.env.REACT_APP_URL}/api/v1/master/tags/lippo-country/`,
    getLippoProvince: `${process.env.REACT_APP_URL}/api/v1/master/tags/lippo-province-list/`,
    // getLippoCity: `${process.env.REACT_APP_URL}/api/v1/master/tags/lippo-city-list/`,
    // getStationList: `${process.env.REACT_APP_URL}/api/v1/master/tags/indonesia-station-list/`,
    // updateUser: `${process.env.REACT_APP_URL}/api/user/update_user/`,
    // updateUserProfile: `${process.env.REACT_APP_URL}/api/user/update_user_profile/`,
    // saveQuoteDeclarations: `${process.env.REACT_APP_URL}/api/v1/master-quote/`,
    // getTravePackageByID: `${process.env.REACT_APP_URL}/api/v1/products/zurich-travel/packages/`,
    // addCouponToQuote: `${process.env.REACT_APP_URL}/api/quote/`,
    // getTravelCountries: `${process.env.REACT_APP_URL}/api/v1/master/tags/zurich-country/`,
    // getTravelPackage: `${process.env.REACT_APP_URL}/api/v1/products/zurich-travel/packages/`,
    // createTravelQuote: `${process.env.REACT_APP_URL}/api/quote/create_master_quote/`,
    getTravelokaAirports: `${process.env.REACT_APP_URL}/api/v1/master/airports/`,
    // getVehicleBrands: `${process.env.REACT_APP_URL}/api/v2/brand/`,
    // getVehicleModels: `${process.env.REACT_APP_URL}/api/v2/model/`,
    submitLead: `${process.env.REACT_APP_URL}/api/v1/leads/`,
    validateQuote: `${process.env.REACT_APP_URL}/api/quote/validate-quote/`,
    // getGadgetBrands: `${process.env.REACT_APP_URL}/api/v1/master/tags/gadget-brand/`,
    // getGadgetPrice: `${process.env.REACT_APP_URL}/api/v1/master/tags/gadget-price-range/?order_by=seq_weight`,
    // getLifePackage: `${process.env.REACT_APP_URL}/api/v1/products/simas-gadget-protection/packages/`,
    // getBrandOutlet: `${process.env.REACT_APP_URL}/api/v1/master/tags/purchase-outlet/`,
    // getMudikPackage: `${process.env.REACT_APP_URL}/api/v1/products/get_mudik_package/`,
    // getFlightPackage: `${process.env.REACT_APP_URL}/api/v1/products/get_flight_package/`,
    // createGosureQuote: `${process.env.REACT_APP_URL}/api/quote/create-mudik-quote/`,
    // createGosureFlightQuote: `${process.env.REACT_APP_URL}/api/quote/create-flight-quote/`,
    // createGosureTrainQuote: `${process.env.REACT_APP_URL}/api/quote/create-multiple-quote/`,
    // createGosureBikeQuote: `${process.env.REACT_APP_URL}/api/quote/create-bike-protection-quote/`,
    // createGosureCrackScreenQuote: `${process.env.REACT_APP_URL}/api/quote/create-gosure-crack-screen/`,
    // getGosureAirportList: `${process.env.REACT_APP_URL}/api/v1/master/tags/airport-list/`,
    chatbotMatchIntent: `${process.env.REACT_APP_URL}/api/chatbot/match/`,
    // getGosureBrands: `${process.env.REACT_APP_URL}/api/v1/master/tags/bike-brand/`,
    // getGosureModels: `${process.env.REACT_APP_URL}/api/v1/master/tags/bike-model/`,
    getCrackScreenCoveragePeriod: `${process.env.REACT_APP_URL}/api/v1/master/tags/crack_screen_ultra_coverage_period/`,
    getCrackScreenCoveragePeriodAmount: `${process.env.REACT_APP_URL}/api/v1/master/tags/crack_screen_ultra_premium_v2/`,
    getCrackScreenPremium: `${process.env.REACT_APP_URL}/api/v1/master/tags/crack_screen_ultra_premium/`,
    createTiaVisitorEntry: `${process.env.REACT_APP_URL}/api/v1/career/tia-visitor/`,
    uploadDoc: `${process.env.REACT_APP_URL}/api/v1/career/applicant-docs/`,
    applyJobapi: `${process.env.REACT_APP_URL}/api/v1/career/apply/`
    // gojekSingleSignOn: `${process.env.REACT_APP_URL}/api/user/gojek-redirect-url/`,
    // gojekData: `${process.env.REACT_APP_URL}/api/user/validate-session/`,
    // applyVehicleCoupon: `${process.env.REACT_APP_URL}/api/v1/master-quote/add-vehicle-coupon/`,
    // validateCreateQuote: `${process.env.REACT_APP_URL}/api/v1/master-quote/validate-create-quote/`
  }
}
// move this to requests folder
