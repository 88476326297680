import actions from "./actions"

const initState = {
	data: {}
}

export default function updateStep(state = initState, action) {
	switch (action.type) {
	case actions.UPDATE_STEP:
		return {
			...state,
			data: {...action.payload}
		}
	default:
		return state
	}
}
