import actions from "./actions"

const initState = {
	data:{},
	message:""
}

export default function quoteReducer(state = initState, action) {
	switch (action.type) {
	case actions.QUOTE_DETAILS:
		return {
			...state,
			data: {...action.data},
			message: ""
		}
	case actions.QUOTE_DETAILS_ERROR:
		return {
			...state,
			quoteDetailsError: {...action.payload, message: action.message}
		}
	default:
		return state
	}
}
