/*
  @Updated
  Robert Maulana
  17, July 2018

  # Add currencyFormatter function
*/

// import { Map } from 'immutable'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function clearStorage () {
  localStorage.clear()
  const allCookies = cookies.getAll()
  for (let key in allCookies) {
    cookies.remove(key, { path: '/' })
  }
}

export function clearToken () {
  localStorage.removeItem('id_token')
  cookies.remove('idToken')
}

export function getDynamicHeader (token) {
  return { 'Content-Type': 'application/json', Authorization: 'JWT ' + token }
}

export function getHeader () {
  // let token = getToken().get('idToken')
  let token = getToken()['idToken']
  console.log(token)
  if (!token) return null
  return { 'Content-Type': 'application/json', Authorization: 'JWT ' + token }
}
export function getCookiesHeader () {
  const token = cookies.get('idToken')
  return { 'Content-Type': 'application/json', Authorization: 'JWT ' + token }
}
export function getFileHeader () {
  // let token = getToken().get('idToken')
  let token = getToken()['idToken']
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: 'JWT ' + token
  }
}

export function getToken () {
  try {
    const idToken = localStorage.getItem('id_token')
    // return new Map({ idToken })
    return { idToken }
  } catch (err) {
    clearToken()
    return {}
  }
}

export function setToken (token) {
  try {
    localStorage.setItem('id_token', token)
  } catch (err) {
    console.log(err)
  }
}

export function timeDifference (givenTime) {
  givenTime = new Date(givenTime)
  const milliseconds = new Date().getTime() - givenTime.getTime()
  const numberEnding = number => {
    return number > 1 ? 's' : ''
  }
  const number = num => (num > 9 ? '' + num : '0' + num)
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000)
    const years = Math.floor(temp / 31536000)
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1)
      const day = number(givenTime.getUTCDate())
      const year = givenTime.getUTCFullYear() % 100
      return `${day}-${month}-${year}`
    }
    const days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days)
      } else {
        // const months = [
        //     'Jan',
        //     'Feb',
        //     'Mar',
        //     'Apr',
        //     'May',
        //     'Jun',
        //     'Jul',
        //     'Aug',
        //     'Sep',
        //     'Oct',
        //     'Nov',
        //     'Dec',
        // ]
        // const month = months[givenTime.getUTCMonth()]
        const day = number(givenTime.getUTCDate())
        return day * 24 * 60
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return hours * 60
    }
    const minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return minutes
    }
    return 0
  }
  return getTime()
}

export function currencyFormatter (val) {
  // let reg = /^[0-9]+$/g
  let reverse = val
    .toString()
    .split('')
    .reverse()
    .join('')

  let ribuan = reverse.match(/\d{1,3}/g)
  ribuan = ribuan
    .join('.')
    .split('')
    .reverse()
    .join('')
  return 'IDR ' + ribuan
}

export function firstCharUpperCase (str) {
  let sp = str.split('')
  let up = sp.length > 0 ? `${sp[0].toUpperCase()}${sp.slice(1).join('')}` : ''
  return up
}

export function setItemToLocalStorage (name, payload) {
  const data = JSON.stringify(payload)
  localStorage.setItem(name, data)
}

export function setItemToLocalWithTimeStamp (name, payload) {
  let date = new Date()
  Object.assign(payload, {
    timeStamp: date
  })
  const data = JSON.stringify(payload)
  localStorage.setItem(name, data)
}

export function getValidatedStorageItem (name) {
  //  let date = new Date()
  let data = localStorage.getItem(name)
  if (data) {
    let parseData = JSON.parse(data)
    let pastTime = new Date(parseData.timeStamp)
    let timeDiff = timeDifference(pastTime)
    let expiry = `${process.env.REACT_APP_TOKEN_EXPIRY}`
    if (timeDiff > expiry) {
      localStorage.removeItem(name)
    } else {
      return parseData.partner
    }
  } else {
    return null
  }
}

export function sleep (time) {
  return new Promise(resolve => setTimeout(resolve, time))
}
