import React from 'react'
import ReactDOM from 'react-dom'
import MainApp from './MainApp'
import 'antd/dist/antd.css'
import './MainApp/main.css'
import 'react-toastify/dist/ReactToastify.css'
import { getInitData } from './helpers/urlSync'
import Cookies from 'universal-cookie'
import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'

const cookies = new Cookies()
const initData = getInitData()
const urlParams = window.location.search

cookies.set('url_params', urlParams, { path: '/' })

if (process.env.REACT_APP_DSN) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_DSN}`,
    // autoSessionTracking: true,
    // integrations: [
    //   new Integrations.BrowserTracing()
    // ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1
  })
}

if (initData.utm_source) {
  cookies.set('utm_source', initData.utm_source, { path: '/' })
}
if (initData.utm_medium) {
  cookies.set('utm_medium', initData.utm_medium, { path: '/' })
}
if (initData.utm_campaign) {
  cookies.set('utm_campaign', initData.utm_campaign, { path: '/' })
}

ReactDOM.render(<MainApp />, document.getElementById('root'))
// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./MainApp', () => { })
}
// TODO remove later to get docker to build again
