import { all, takeEvery, fork, put, call } from 'redux-saga/effects'
import _get from 'lodash.get'

import actions from './actions'
import get from '../../request/methods/get'
import post from '../../request/methods/post'
import crackscreenUrls from '../../request/urls/crackscreen'

export function * getCrackscreenBrandModels () {
  yield takeEvery(actions.GET_CRACKSCREEN_BRAND_MODELS_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(get, { ...payload, url: crackscreenUrls.getPhoneModels })
      if ([200, 201].includes(res.status)) {
        yield put({ type: actions.GET_CRACKSCREEN_BRAND_MODELS_SUCCESS, data: res.data, message: 'success' })
      } else {
        yield put({ type: actions.GET_CRACKSCREEN_BRAND_MODELS_FAILURE, data: [], message: _get(res, 'data.error', 'Failed') })
      }
    } catch (e) {
      yield put({ type: actions.GET_CRACKSCREEN_BRAND_MODELS_FAILURE, data: [], message: _get(e, 'data.error', 'Failed') })
    }
  })
}

export function * getCrackscreenCoveragePeriodPremium () {
  yield takeEvery(actions.GET_CRACKSCREEN_PREMIUM_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(get, { ...payload, url: crackscreenUrls.getMasterData })
      if ([200, 201].includes(res.status)) {
        yield put({ type: actions.GET_CRACKSCREEN_PREMIUM_SUCCESS, data: res.data, message: 'success' })
      } else {
        yield put({ type: actions.GET_CRACKSCREEN_PREMIUM_FAILURE, data: [], message: _get(res, 'data.error', 'Failed') })
      }
    } catch (e) {
      yield put({ type: actions.GET_CRACKSCREEN_PREMIUM_FAILURE, data: [], message: _get(e, 'data.error', 'Failed') })
    }
  })
}

export function * getCrackscreenCoveragePeriod () {
  yield takeEvery(actions.GET_CRACKSCREEN_COVERAGE_PERIOD_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(get, { ...payload, url: crackscreenUrls.getMasterData })
      if ([200, 201].includes(res.status)) {
        yield put({ type: actions.GET_CRACKSCREEN_COVERAGE_PERIOD_SUCCESS, data: res.data, message: 'success' })
      } else {
        yield put({ type: actions.GET_CRACKSCREEN_COVERAGE_PERIOD_FAILURE, data: [], message: _get(res, 'data.error', 'Failed') })
      }
    } catch (e) {
      yield put({ type: actions.GET_CRACKSCREEN_COVERAGE_PERIOD_FAILURE, data: [], message: _get(e, 'data.error', 'Failed') })
    }
  })
}

export function * createCrackcreenQuote () {
  yield takeEvery(actions.CREATE_CRACKSCREEN_QUOTE_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(post, { ...payload, url: crackscreenUrls.validateCreateQuote })
      if ([200, 201].includes(res.status)) {
        yield put({ type: actions.CREATE_CRACKSCREEN_QUOTE_SUCCESS, data: res.data, message: 'success' })
      } else {
        yield put({ type: actions.CREATE_CRACKSCREEN_QUOTE_FAILURE, data: {}, message: _get(res, 'data.error', 'Failed') })
      }
    } catch (e) {
      yield put({ type: actions.CREATE_CRACKSCREEN_QUOTE_FAILURE, data: {}, message: _get(e, 'data.error', 'Failed') })
    }
  })
}

export function * calculateCrackscreenQuoteCoupon () {
  yield takeEvery(actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(post, { ...payload, url: crackscreenUrls.calculateCoupon })
      if ([200, 201].includes(res.status)) {
        const discountAmount = _get(res, 'data.discount_amount')
        yield put({
          type: actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_SUCCESS,
          discountAmount,
          message: 'Kupon berhasil digunakan',
          success: true
        })
      } else {
        yield put({
          type: actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_FAILURE,
          discountAmount: 0,
          message: 'Kupon gagal digunakan',
          success: false
        })
      }
    } catch (e) {
      yield put({
        type: actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_FAILURE,
        discountAmount: 0,
        message: 'Kupon gagal digunakan',
        success: false
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getCrackscreenBrandModels),
    fork(getCrackscreenCoveragePeriodPremium),
    fork(getCrackscreenCoveragePeriod),
    fork(createCrackcreenQuote),
    fork(calculateCrackscreenQuoteCoupon)
  ])
}
