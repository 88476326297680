import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentStyleWrapper from './index.style'
import { Button, Row, Col, Card } from 'antd'
import { clearStorage } from '../../../helpers/utility'

class Page404 extends Component {
    goHome = () => {
      const { history } = this.props
      clearStorage()
      history.push('/')
    }

    render () {
      return (
        <PaymentStyleWrapper className='bg-dark'>
          <Row type='flex' justify='center' align='middle'>
            <Col span={17}>
              <Card>
                <Row type='flex' justify='center' align='middle'>
                  <h3 className='mb-30'>Page not found!</h3>
                </Row>
                <Row type='flex' justify='center' align='left'>
                  <Col className='text-center'>
                    <Button className='button-primary' onClick={this.goHome}>KEMBALI KE BERANDA</Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </PaymentStyleWrapper>
      )
    }
}

export default connect()(Page404)
