const actions = {
  GET_CRACKSCREEN_BRAND_MODELS_REQUEST: 'GET_CRACKSCREEN_BRAND_MODELS_REQUEST',
  GET_CRACKSCREEN_BRAND_MODELS_SUCCESS: 'GET_CRACKSCREEN_BRAND_MODELS_SUCCESS',
  GET_CRACKSCREEN_BRAND_MODELS_FAILURE: 'GET_CRACKSCREEN_BRAND_MODELS_FAILURE',
  GET_CRACKSCREEN_PREMIUM_REQUEST: 'GET_CRACKSCREEN_PREMIUM_REQUEST',
  GET_CRACKSCREEN_PREMIUM_SUCCESS: 'GET_CRACKSCREEN_PREMIUM_SUCCESS',
  GET_CRACKSCREEN_PREMIUM_FAILURE: 'GET_CRACKSCREEN_PREMIUM_FAILURE',
  GET_CRACKSCREEN_COVERAGE_PERIOD_REQUEST: 'GET_CRACKSCREEN_COVERAGE_PERIOD_REQUEST',
  GET_CRACKSCREEN_COVERAGE_PERIOD_SUCCESS: 'GET_CRACKSCREEN_COVERAGE_PERIOD_SUCCESS',
  GET_CRACKSCREEN_COVERAGE_PERIOD_FAILURE: 'GET_CRACKSCREEN_COVERAGE_PERIOD_FAILURE',
  CREATE_CRACKSCREEN_QUOTE_REQUEST: 'CREATE_CRACKSCREEN_QUOTE_REQUEST',
  CREATE_CRACKSCREEN_QUOTE_SUCCESS: 'CREATE_CRACKSCREEN_QUOTE_SUCCESS',
  CREATE_CRACKSCREEN_QUOTE_FAILURE: 'CREATE_CRACKSCREEN_QUOTE_FAILURE',
  CALCULATE_CRACKSCREEN_QUOTE_COUPON_REQUEST: 'CALCULATE_CRACKSCREEN_QUOTE_COUPON_REQUEST',
  CALCULATE_CRACKSCREEN_QUOTE_COUPON_SUCCESS: 'CALCULATE_CRACKSCREEN_QUOTE_COUPON_SUCCESS',
  CALCULATE_CRACKSCREEN_QUOTE_COUPON_FAILURE: 'CALCULATE_CRACKSCREEN_QUOTE_COUPON_FAILURE',
  RESET_CRACKSCREEN_STATES: 'RESET_CRACKSCREEN_STATES',
  getCrackscreenBrandModels: payload => ({
    type: actions.GET_CRACKSCREEN_BRAND_MODELS_REQUEST,
    payload
  }),
  getCrackscreenPremium: payload => ({
    type: actions.GET_CRACKSCREEN_PREMIUM_REQUEST,
    payload
  }),
  getCrackscreenCoveragePeriod: payload => ({
    type: actions.GET_CRACKSCREEN_COVERAGE_PERIOD_REQUEST,
    payload
  }),
  createCrackScreenQuote: payload => ({
    type: actions.CREATE_CRACKSCREEN_QUOTE_REQUEST,
    payload
  }),
  calculateCrackScreenCoupon: payload => ({
    type: actions.CALCULATE_CRACKSCREEN_QUOTE_COUPON_REQUEST,
    payload
  }),
  resetCrackscreenRedux: payload => ({
    type: actions.RESET_CRACKSCREEN_STATES,
    payload
  })
}
export default actions
