const actions = {
  // upload documents actions
  UPLOAD_DOC_REQUEST: 'UPLOAD_DOC_REQUEST',
  UPLOAD_DOC_SUCCESS: 'UPLOAD_DOC_SUCCESS',
  UPLOAD_DOC_ERROR: 'UPLOAD_DOC_ERROR',

  // apply-applications actions
  APPLY_DOC_REQUEST: 'APPLY_DOC_REQUEST',
  APPLY_DOC_SUCCESS: 'APPLY_DOC_SUCCESS',
  APPLY_DOC_ERROR: 'APPLY_DOC_ERROR',

  RESET_DATA_CAREER: 'RESET_DATA_CAREER',

  uploadDocuments: payload => ({
    type: actions.UPLOAD_DOC_REQUEST,
    payload
  }),

  uploadDocumentsSuccess: payload => ({
    type: actions.UPLOAD_DOC_SUCCESS,
    payload
  }),
  uploadDocumentsError: payload => ({
    type: actions.UPLOAD_DOC_ERROR,
    payload
  }),

  applyJob: payload => ({
    type: actions.APPLY_DOC_REQUEST,
    payload
  }),
  resetDataCareer: payload => ({
    type: actions.RESET_DATA_CAREER,
    payload
  })
}
export default actions
