import actions from './actions'

const initState = {
  uploadData: [],
  uploadMessage: '',
  applyFormStatus: ''
}

export default function Career (state = initState, action) {
  switch (action.type) {
    case actions.RESET_DATA_CAREER:
      if (action.payload && Array.isArray(action.payload)) {
        let stateToBeReset = {}
        action.payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state
        }
      }
    case actions.UPLOAD_DOC_SUCCESS:
    case actions.UPLOAD_DOC_ERROR:
      return {
        ...state,
        uploadData: action.data,
        uploadMessage: action.message
      }
    case actions.APPLY_DOC_SUCCESS:
    case actions.APPLY_DOC_ERROR:
      return {
        ...state,
        applyFormStatus: action.message
      }
    default:
      return state
  }
}
