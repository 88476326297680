import config, {
	getCurrentLanguage
} from "../../containers/LanguageSwitcher/config"

import actions from "./actions"

const initState = {
	isActivated: false,
	language: getCurrentLanguage(config.defaultLanguage || "english")
}

export default function(state = initState, action) {
	switch (action.type) {
	case actions.ACTIVATE_LANG_MODAL:
		return state.set("isActivated", !state.get("isActivated"))
	case actions.CHANGE_LANGUAGE:
		let lang = "english"
		if(action.language === "id") {
			lang = "indonesian"
		}
		return {...state, language: getCurrentLanguage(lang)}
	default:
		return state
	}
}
