export const IMAGE_URL = {
  logo: 'https://storage.googleapis.com/pp_img/website/pp-logo-yellow-cap.png',
  phone: 'https://storage.googleapis.com/pp_img/website/phone.png',
  whatsapp: 'https://storage.googleapis.com/pp_img/website/whatsapp.png',
  indonasia: 'https://storage.googleapis.com/pp_img/website/flag_indonesia.png',
  english: 'https://storage.googleapis.com/pp_img/website/flag_eng.png'
}

export const localeMobileData = [
  {
    locale: 'id',
    localeFlagImage: IMAGE_URL.indonasia
  },
  {
    locale: 'en',
    localeFlagImage: IMAGE_URL.english
  }
]
