import React, { useCallback, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor, history } from '../redux/store'
import PublicRoutes from '../router'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'
import IntlAppWrapper from '../components/IntlWrapper'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

// import {Helmet} from 'react-helmet'
// import Snow from '../components/Snow'

const MainApp = () => {
  const [locale, setLocale] = useState('')

  useEffect(() => {
    if ((cookies.get('pp-locale') === 'vn') || !cookies.get('pp-locale')) {
      cookies.set('pp-locale', 'id')
    }

    setLocale(cookies.get('pp-locale'))
  }, [])

  const handleLocaleChange = useCallback((locale) => {
    setLocale(locale)
    if (locale === 'en' || locale === 'id') {
      cookies.set('pp-locale', locale)
    } else if (locale === 'vn') {
      let domainName = window.location.hostname.split('.')
      domainName.splice(-1, 1, 'vn')

      window.location.replace('https://' + domainName.join('.'))
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IntlAppWrapper locale={locale}>
            <PublicRoutes
              history={history}
              onLocaleChange={handleLocaleChange}
              locale={locale}
            />
          </IntlAppWrapper>
          {/* <Snow /> */}
          <ToastContainer />
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}
export default MainApp
