const actions = {
	QUOTE_DETAILS:"QUOTE_DETAILS",
	QUOTE_DETAILS_ERROR:"QUOTE_DETAILS_ERROR",
	quoteDetails: payload =>({
		type:actions.QUOTE_DETAILS,
		payload
	}),
	quoteDetailsError: payload =>({
		type:actions.QUOTE_DETAILS_ERROR,
		payload
	})

}
export default actions
