import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import createHistory from 'history/createBrowserHistory'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import reducers from '../redux/reducers'
import rootSaga from '../redux/sagas'

const history = createHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]

const blacklistPersonalDetailKey = createBlacklistFilter('PersonalDetail', ['getPersonalDetailError'])

const blacklistUserProfileKey = createBlacklistFilter('UserProfile', ['getUserProfileError'])

const blacklistQuoteDetailsKey = createBlacklistFilter('quoteDetails', ['quoteDetailsError'])

const blacklistCareer = createBlacklistFilter('Career', ['applyFormStatus', 'uploadData', 'uploadMessage'])

const blackListCrackscreen = createBlacklistFilter('Crackscreen', [])

const blackListApp = createBlacklistFilter('App', [])

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['Payment', 'Crackscreen', 'App'],
  transforms: [
    blacklistPersonalDetailKey,
    blacklistUserProfileKey,
    blacklistQuoteDetailsKey,
    blacklistCareer,
    blackListCrackscreen,
    blackListApp
  ]
}

const store = createStore(
  persistReducer(persistConfig, combineReducers({
    ...reducers,
    router: routerReducer
  })),
  compose(applyMiddleware(...middlewares))
)

const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

export { store, persistor, history }
