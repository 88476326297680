import { MobileLanguageChangeStyleWrapper } from './index.style'
import { localeMobileData } from './constants'

const MobileLanguageSwitcher = ({ localeData = localeMobileData, onLocaleChange, currentLocale }) => {

  return (
    <MobileLanguageChangeStyleWrapper>
      <ul>
        {
          localeData.map(({ locale, localeFlagImage }) => (
            <li key={locale}>
              <div
                className={`flag-container ${currentLocale === locale ? 'active' : ''}`}
                onClick={() => onLocaleChange(locale)}
              >
                <img alt={`${locale} flag`} src={localeFlagImage} />
              </div>
            </li>
          ))
        }
      </ul>
    </MobileLanguageChangeStyleWrapper>
  )
}
export default MobileLanguageSwitcher
